import React from 'react';
import client from './src/graphql/client';
import { ApolloProvider } from '@apollo/client';
import './src/styles/base.scss';
import 'typeface-open-sans';

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
);
