import fetch from 'isomorphic-fetch';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://xup-app-hasura-graphql-engine.herokuapp.com/v1/graphql',
    fetch,
  }),
  cache: new InMemoryCache(),
});

export default client;
